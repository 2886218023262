// src/App.js
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./screen/Login";
import Dashboard from "./screen/Dashboard";
import FormAaa from "./screen/form_aaa";
import HomePage from "./AAA/index";
import Statitics from "./screen/statitics";
import PromotionForm from "./screen/PromotionForm";
import SupportForm from "./screen/Support";
import AfterHoursForm from "./screen/AAACallCenter";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/form_aaa" element={<FormAaa />} />
        <Route path="/Home" element={<HomePage />} />
        <Route path="/statitics" element={<Statitics />} />
        <Route path="/promos" element={<PromotionForm />} />
        <Route path="/support" element={<SupportForm />} />
        <Route path="/aaacallcenter" element={<AfterHoursForm />} />

      </Routes>
    </Router>
  );
};

export default App;
