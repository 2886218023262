"use client";

import React, { useState } from "react";

import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFire,
  faBan,
  faClock,
  faLock,
  faEnvelopeOpen,
} from "@fortawesome/free-solid-svg-icons";
import globalVariables from "../GlobalVariable";
import ReCAPTCHA from "react-google-recaptcha";
import Turnstile, { useTurnstile } from "react-turnstile";

import "../App.css";

const Statitics = () => {
  return <></>;
};

export default Statitics;
