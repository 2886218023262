import React, { useState, useEffect } from "react";
import axios from "axios";
import globalVariables from "../../GlobalVariable";

const ModalListenRecording = ({
  handleCloseModalRecording,
  messageSelected,
  isModalOpenRecording,
}) => {
  const [recordingBlob, setRecordingBlob] = useState(null); // Almacenar el audio en Blob
  const [transcriptions, setTranscriptions] = useState([]); // Almacenar las transcripciones
  const [loading, setLoading] = useState(false); // Estado de carga

  const getRecording = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${globalVariables.domain}/lead_systematik/recording`,
        {
          headers: {
            message_id: messageSelected.id,
            location_id: messageSelected.locationId,
          },
          responseType: "arraybuffer",
        }
      );

      const audioBlob = new Blob([response.data], { type: "audio/mpeg" });
      setRecordingBlob(audioBlob);
    } catch (error) {
      console.error("Error al obtener la grabación:", error);
    } finally {
      setLoading(false);
    }
  };

  const getTranscription = async () => {
    try {
      const response = await axios.get(
        `${globalVariables.domain}/lead_systematik/transcription`,
        {
          headers: {
            message_id: messageSelected.id,
            location_id: messageSelected.locationId,
          },
        }
      );

      setTranscriptions(response.data.content); // Guardar las transcripciones en el estado
    } catch (error) {
      console.error("Error al obtener las transcripciones:", error);
    }
  };

  useEffect(() => {
    if (isModalOpenRecording) {
      getRecording();
      getTranscription();
    }
  }, [isModalOpenRecording]);

  const recordingUrl = recordingBlob
    ? URL.createObjectURL(recordingBlob)
    : null;

  useEffect(() => {
    return () => {
      if (recordingUrl) {
        URL.revokeObjectURL(recordingUrl);
      }
    };
  }, [recordingUrl]);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60); // Obtener minutos
    const seconds = Math.floor(time % 60); // Obtener segundos
    return `${minutes}:${seconds.toString().padStart(2, "0")}`; // Formatear con ceros a la izquierda
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-40 bg-black bg-opacity-50">
      <div className="relative bg-white rounded-lg p-6 w-11/12 md:w-1/3 max-h-[80%]">
        {/* Botón para cerrar el modal */}
        <button
          onClick={handleCloseModalRecording}
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-800 focus:outline-none text-2xl"
        >
          &#x2715;
        </button>

        <p className="font-bold mb-4">Recording</p>

        {/* Mostrar el reproductor de audio o el estado de carga */}
        {loading ? (
          <p className="mt-4 text-gray-500">Loading recording...</p>
        ) : recordingUrl ? (
          <audio controls className="mt-4 w-full">
            <source src={recordingUrl} type="audio/mpeg" />
            Your browser does not support the audio element.
          </audio>
        ) : (
          <p className="mt-4 text-red-500">No recording available</p>
        )}

        {/* Sección de transcripciones con scroll */}
        <div className="mt-6">
          <p className="font-bold mb-2">Transcriptions:</p>
          <div className="max-h-48 overflow-y-auto border-t border-gray-200 pt-2">
            {transcriptions.length > 0 ? (
              <ul className="space-y-2">
                {transcriptions.map((transcription, index) => (
                  <li key={index} className="border-b py-2">
                    <p>
                      <strong>Time:</strong>{" "}
                      {formatTime(parseFloat(transcription.startTime))} -{" "}
                      {formatTime(parseFloat(transcription.endTime))}
                    </p>

                    <p>
                      <strong>Speaker:</strong> Channel{" "}
                      {transcription.mediaChannel}
                    </p>
                    <p>
                      <strong>Text:</strong> {transcription.transcript}
                    </p>
                  </li>
                ))}
              </ul>
            ) : (
              <p className="text-gray-500">No transcriptions available</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalListenRecording;
