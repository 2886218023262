import React, { useState } from "react";
import axios from "axios";
import Spinner from "./components/spinner";
import Logo from "../img/Logo.png";

const SupportForm = () => {
  const [formData, setFormData] = useState({
    timestamp: new Date().toISOString(),
    companyName: "",
    requestorName: "",
    email: "",
    phone: "",
    requestType: "",
    otherRequest: "",
    description: "",
    priority: "Low",
  });

  const [files, setFiles] = useState([]); // Cambiado a array
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    setFiles([...e.target.files]); // Guardar múltiples archivos en el estado
  };

  async function postFiles(files) {
    const uploadedUrls = [];

    for (const file of files) {
      const fileFormData = new FormData();
      fileFormData.append("file", file); // Cambiar "image" a "file"

      try {
        const response = await axios.post(
          `https://api.samedayvet.com/file`,
          fileFormData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (response.data.success === true) {
          uploadedUrls.push(response.data.content[0]); // Guardar la URL subida
        }
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    }

    return uploadedUrls;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    let fileUrls = [];
    if (files && files.length > 0) {
      fileUrls = await postFiles(files); // Subir múltiples archivos
    }

    const pacificDate = new Date().toLocaleString("en-US", {
      timeZone: "America/Los_Angeles",
      dateStyle: "short",
      timeStyle: "medium",
    });

    try {
      const response = await axios.post(
        "https://v1.nocodeapi.com/adan/google_sheets/lCQGiYbQvMTmoilR/addRows?tabId=Sheet2",
        [
          {
            Timestamp: formData.timestamp,
            "Company Name": formData.companyName,
            "Requestor Name": formData.requestorName,
            "Email Address": formData.email,
            "Phone Number": formData.phone,
            "Request Type": formData.requestType,
            "Other Request": formData.otherRequest,
            "Detailed Description": formData.description,
            "Priority Level": formData.priority,
            "File URLs": fileUrls.join("\n") || "No files uploaded", "Submission Date": pacificDate,
          },
        ],
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      setShowModal(true);
      setFormData({
        timestamp: new Date().toISOString(),
        companyName: "",
        requestorName: "",
        email: "",
        phone: "",
        requestType: "",
        otherRequest: "",
        description: "",
        priority: "Low",
      });
      setFiles(null);
      setLoading(false);
    } catch (error) {
      console.error(
        "Error submitting form:",
        error.response?.data || error.message
      );
      alert("Error submitting form");
      setLoading(false);
    }
  };

  return (
    <>
      <form
        onSubmit={handleSubmit}
        className="max-w-lg mx-auto p-8 bg-white shadow-md rounded-lg space-y-6"
      >
        <h2 className="text-2xl font-bold text-center">Support Request Form</h2>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Company Name
          </label>
          <input
            type="text"
            name="companyName"
            className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            value={formData.companyName}
            onChange={handleChange}
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Requestor Name (<span className="text-red-500">required</span>)
          </label>
          <input
            type="text"
            name="requestorName"
            required
            className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            value={formData.requestorName}
            onChange={handleChange}
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Email Address (<span className="text-red-500">required</span>)
          </label>
          <input
            type="email"
            name="email"
            required
            className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            value={formData.email}
            onChange={handleChange}
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Phone Number
          </label>
          <input
            type="number"
            name="phone"
            className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            value={formData.phone}
            onChange={handleChange}
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Request Type
          </label>
          <select
            name="requestType"
            className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            value={formData.requestType}
            onChange={handleChange}
          >
            <option value="" disabled>
              Select Request Type
            </option>
            <option value="Technical Support">Technical Support</option>
            <option value="Service Request">Service Request</option>
            <option value="Software/Hardware Updates">
              Software/Hardware Updates
            </option>
            <option value="Billing Inquiry">Billing Inquiry</option>
            <option value="Other">Other</option>
          </select>
          {formData.requestType === "Other" && (
            <input
              type="text"
              name="otherRequest"
              placeholder="Please specify"
              className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={formData.otherRequest}
              onChange={handleChange}
            />
          )}
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Detailed Description
          </label>
          <textarea
            name="description"
            placeholder="e.g., error messages, specific functions not working, or features you’d like to update."
            className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            value={formData.description}
            onChange={handleChange}
            rows="4"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Priority Level
          </label>
          <select
            name="priority"
            className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            value={formData.priority}
            onChange={handleChange}
          >
            <option value="Low">Low (Not urgent)</option>
            <option value="Medium">Medium (Within 72 hours)</option>
            <option value="High">High (Urgent)</option>
          </select>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            File Uploads (Optional)
          </label>
          <input
            type="file"
            name="files"
            multiple
            className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            onChange={handleFileChange}
          />
        </div>

        <button
          type="submit"
          className="mt-6 w-full py-3 bg-blue-600 text-white font-semibold rounded-md shadow-md hover:bg-blue-700 transition duration-300"
        >
          {loading ? <Spinner /> : "Submit"}
        </button>
      </form>

      {showModal && (
        <div className="fixed inset-0 bg-white flex justify-center items-center">
          <div className="flex flex-col justify-center items-center bg-white rounded-lg p-8 max-w-sm w-full">
            <img src={Logo} width={300} alt="Logo" />
            <h3 className="text-xl text-center my-4">
              Thank you! Your support request has been submitted.
            </h3>
            <button
              onClick={() => {
                // setShowModal(false);
                window.location.reload();
              }}
              className="block mx-auto bg-red-600 text-white py-2 px-4 rounded-md hover:bg-red-700 transition duration-300"
            >
              Submit another request
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default SupportForm;
