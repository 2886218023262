import React, { useState } from "react";
import axios from "axios";
import globalVariables from "../GlobalVariable";

const AfterHoursForm = () => {
  const [formData, setFormData] = useState({
    urgency: "Moderate Urgency",
    fullName: "",
    phoneNumber: "",
    email: "",
    address: "",
    serviceNeeded: "",
    otherNeeds: "",
    otherDetails: "",
    preferredDateTime: "",
  });

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  function obtenerTimestampActual() {
    return Date.now();
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    // return;

    try {
      const data = {
        conversationId: "N/A",
        locationId: "AAA Call Center",
        lastMessageDate: obtenerTimestampActual(),
        fullName: formData.fullName,
        tags: [formData, "aaa"],
        email: formData.email,
        phone: formData.phoneNumber,
      };
      const response = await axios.post(
        `${globalVariables.domain}/lead_systematik/`,
        {
          conversationId: data.conversationId,
          locationId: data.locationId,
          lastMessageDate: data.lastMessageDate,
          //   lastMessageType: data.lastMessageType,
          //   lastMessageBody: data.lastMessageBody,
          //   contactId: data.contactId,
          fullName: data.fullName,
          //   contactName: data.contactName,
          phone: data.phone,
          tags: data.tags,
          //   companyName: data.companyName,
          email: data.email,
          //   lead_source: data.lead_source,
          //   messages: data.messages,
        }
      );

      const timestamp = new Intl.DateTimeFormat("en-US", {
        timeZone: "America/Los_Angeles",
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      }).format(new Date());

      const preferredDateTime = new Date(
        formData.preferredDateTime
      ).toLocaleString("en-US", {
        dateStyle: "medium",
        timeStyle: "short",
      });
      const body = `🚨 New Lead from AAA Call Center 🚨
        Name: ${formData.fullName}
        Phone: ${formData.phoneNumber}
        Service: ${formData.serviceNeeded}
        Urgency: ${formData.urgency}
        Preferred Date: ${preferredDateTime}
        Timestamp: ${timestamp}`;
      //   console.log(response.data.content);
      await sendSms("+14087128521", body);
      setFormData({
        urgency: "Moderate Urgency",
        fullName: "",
        phoneNumber: "",
        email: "",
        address: "",
        serviceNeeded: "",
        otherNeeds: "",
        otherDetails: "",
        preferredDateTime: "",
      });
      setLoading(false);
    } catch (error) {
      console.error("Error updating lead status:", error);
      alert("There was an issue submitting the form. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const sendSms = async (to, body) => {
    try {
      const response = await axios.post(`${globalVariables.domain}/sms`, {
        body: body,
        to: to,
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="max-w-lg mx-auto p-8 bg-white shadow-md rounded-lg space-y-6">
      <h2 className="text-2xl font-bold text-center">
        Afterhours Hot Lead Form
      </h2>

      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Urgency
          </label>
          <select
            name="urgency"
            value={formData.urgency}
            onChange={handleChange}
            className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            <option value="Moderate Urgency">Moderate Urgency</option>
            <option value="Routine Low Concern">Routine Low Concern</option>
            <option value="Emergency">Emergency</option>
          </select>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Full Name (<span className="text-red-500">required</span>)
          </label>
          <input
            type="text"
            name="fullName"
            value={formData.fullName}
            onChange={handleChange}
            className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            required
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Phone Number (<span className="text-red-500">required</span>)
          </label>
          <input
            type="text"
            name="phoneNumber"
            value={formData.phoneNumber}
            onChange={handleChange}
            className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            required
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Email
          </label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            // required
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Address
          </label>
          <input
            type="text"
            name="address"
            value={formData.address}
            onChange={handleChange}
            className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Service Needed
          </label>
          <select
            name="serviceNeeded"
            value={formData.serviceNeeded}
            onChange={handleChange}
            className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            <option value="" disabled>
              Select Service
            </option>
            <option value="Furnace Installation and Repair">
              Furnace Installation and Repair
            </option>
            <option value="Air Conditioning Installation and Repair">
              Air Conditioning Installation and Repair
            </option>
            <option value="Water Heater Installation, Repair, and Maintenance">
              Water Heater Installation, Repair, and Maintenance
            </option>
            <option value="HVAC System Maintenance and Tune-Ups">
              HVAC System Maintenance and Tune-Ups
            </option>
            <option value="Indoor Air Quality Solutions">
              Indoor Air Quality Solutions
            </option>
            <option value="Duct Cleaning and Sealing">
              Duct Cleaning and Sealing
            </option>
            <option value="Heat Pump Installation and Repair">
              Heat Pump Installation and Repair
            </option>
            <option value="Thermostat Installation and Programming">
              Thermostat Installation and Programming
            </option>
            <option value="Emergency HVAC Services">
              Emergency HVAC Services
            </option>
            <option value="Other">Other</option>
          </select>
          {formData.serviceNeeded === "Other" && (
            <input
              type="text"
              name="otherNeeds"
              value={formData.otherNeeds}
              onChange={handleChange}
              placeholder="Please specify other needs"
              className="mt-2 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          )}
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Other Details
          </label>
          <textarea
            name="otherDetails"
            value={formData.otherDetails}
            onChange={handleChange}
            rows="4"
            className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Preferred Appointment Date/Time
          </label>
          <input
            type="datetime-local"
            name="preferredDateTime"
            value={formData.preferredDateTime}
            onChange={handleChange}
            className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>

        <button
          type="submit"
          className="w-full py-3 bg-blue-600 text-white font-semibold rounded-md shadow-md hover:bg-blue-700 transition duration-300"
          disabled={loading}
        >
          {loading ? "Submitting..." : "Submit"}
        </button>

        {success && (
          <p className="text-green-500 mt-4">Form submitted successfully!</p>
        )}
      </form>
    </div>
  );
};

export default AfterHoursForm;
