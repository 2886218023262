"use client";

import React, { useState, useEffect } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFire,
  faBan,
  faClock,
  faLock,
  faEnvelopeOpen,
} from "@fortawesome/free-solid-svg-icons";
import globalVariables from "../../GlobalVariable";
import Yelp from "../../../src/img/yelp.png";
import google from "../../../src/img/google.png";
import internet from "../../../src/img/internet.png";
import yelp_call from "../../../src/img/yelp_call.png";
import aaa_call_center from "../../../src/img/aaa_call_center.png";

import aaa_form_website from "../../../src/img/aaa_form_website.png";
import ModalListenRecording from "./ModalListenRecording";

const getStatusIcon = (status) => {
  switch (status) {
    case "fire":
      return (
        <FontAwesomeIcon
          icon={faFire}
          className="animate-jump animate-infinite animate-duration-[1000ms] animate-delay-1000 animate-ease-in text-orange-500 fa-xl"
        />
      );
    case "no interested":
      return <FontAwesomeIcon icon={faBan} className="text-red-500 fa-xl" />;
    case "30 days time":
      return <FontAwesomeIcon icon={faClock} className="text-blue-500 fa-xl" />;
    case "blocked us":
      return <FontAwesomeIcon icon={faLock} className="text-red-500 fa-xl" />;
    case "called/emailed":
      return (
        <FontAwesomeIcon
          icon={faEnvelopeOpen}
          className="text-green-500 fa-xl"
        />
      );
    default:
      return <FontAwesomeIcon icon={faFire} className="text-gray-500 fa-xl" />;
  }
};

const LeadScriptToggle = ({
  leadId,
  initialScriptState,
  onUpdate,
  fetchLeads,
  businessSelected,
}) => {
  const [scriptIsOn, setScriptIsOn] = useState(
    initialScriptState === null || initialScriptState === undefined
      ? true
      : initialScriptState
  );
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (initialScriptState === null || initialScriptState === undefined) {
      updateLeadScript(true, businessSelected);
    }
  }, []);

  const updateLeadScript = async (newState, businessSelected) => {
    try {
      await axios.patch(`${globalVariables.domain}/lead/${leadId}`, {
        script_is_on: newState,
      });
      setScriptIsOn(newState);
      fetchLeads(businessSelected);

      onUpdate(newState);
    } catch (error) {
      console.error("Error updating lead script state:", error);
    }
  };

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleOptionClick = (value) => {
    updateLeadScript(value === "on", businessSelected);
    setIsOpen(false);
  };

  return (
    <div className="flex">
      <button
        onClick={toggleDropdown}
        className="w-full flex items-center justify-between px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        aria-haspopup="listbox"
        aria-expanded={isOpen}
      >
        <span className="flex items-center">
          <span
            className={`w-2.5 h-2.5 mr-2 rounded-full ${
              scriptIsOn ? "bg-green-500" : "bg-red-500"
            }`}
          ></span>
          {scriptIsOn ? "ON" : "OFF"}
        </span>
        <svg
          className="w-5 h-5 ml-2 -mr-1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            fillRule="evenodd"
            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
      </button>
      {isOpen && (
        <ul
          className="absolute z-10 w-full mt-1 bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
          tabIndex="-1"
          role="listbox"
        >
          {["on", "off"].map((option) => (
            <li
              key={option}
              className={`cursor-default select-none relative py-2 pl-3 pr-9 hover:bg-indigo-600 hover:text-white ${
                scriptIsOn === (option === "on") ? "bg-indigo-100" : ""
              }`}
              onClick={() => handleOptionClick(option)}
              role="option"
              aria-selected={scriptIsOn === (option === "on")}
            >
              <div className="flex items-center">
                <span
                  className={`w-2.5 h-2.5 mr-2 rounded-full ${
                    option === "on" ? "bg-green-500" : "bg-red-500"
                  }`}
                ></span>
                <span
                  className={`font-normal block truncate ${
                    scriptIsOn === (option === "on") ? "font-semibold" : ""
                  }`}
                >
                  {option.toUpperCase()}
                </span>
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

const LeadTable = () => {
  const [leads, setLeads] = useState([]);
  const [sortBy, setSortBy] = useState("timeDesc");
  const [expandedLeadId, setExpandedLeadId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [updatingLeadId, setUpdatingLeadId] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const leadsPerPage = 10;

  const [yelpIds, setYelpIds] = useState("");

  const [businessSelected, setBusinessSelected] = useState("");

  useState(() => {
    const businessYelpIds = localStorage.getItem("business_yelp_ids");
    // console.log(businessYelpIds, "eeeeeeeeee");

    if (!businessYelpIds.includes(",")) {
      setYelpIds([businessYelpIds]);
      // fetchLeads(businessYelpIds);
      setBusinessSelected(businessYelpIds);
    } else {
      // Si la cadena contiene valores separados por comas, conviértelo en un array
      const businessYelpIdsArray = businessYelpIds
        ? businessYelpIds.split(",")
        : [];

      // Verifica el resultado
      // console.log(businessYelpIdsArray);

      setYelpIds(businessYelpIdsArray);
    }
  }, []);

  const fetchLeads = async (business_yelp_id) => {
    try {
      const response = await axios.get(`${globalVariables.domain}/lead`, {
        headers: {
          business_yelp_id: business_yelp_id,
        },
      });

      // Obtén los leads desde getLeadsSystematik
      const leadsSystematik = await getLeadsSystematik(business_yelp_id);

      // Combina ambos arrays de leads
      const combinedLeads = [...response.data.content, ...leadsSystematik];

      // Establece los leads combinados en setLeads
      await setLeads(combinedLeads);

      // Debugging opcional
      // console.log("API Leads:", response.data.content);
      // console.log("Systematik Leads:", leadsSystematik);
      // console.log("Combined Leads:", combinedLeads);
    } catch (error) {
      console.error("Error fetching leads:", error);
    }
  };

  const updateLeadStatus = async (lead_id, newStatus) => {
    setUpdatingLeadId(lead_id);
    setErrorMessage("");
    try {
      const response = await axios.patch(
        `${globalVariables.domain}/lead/${lead_id}`,
        {
          lead_status: newStatus,
        }
      );

      if (response.data.success && response.data.content === null) {
        // throw new Error("Server returned null content");
      }

      setLeads(
        leads.map((lead) =>
          lead._id === lead_id ? { ...lead, lead_status: newStatus } : lead
        )
      );
      alert("Lead status updated successfully!");
    } catch (error) {
      console.error("Error updating lead status:", error);
      setErrorMessage("Failed to update lead status. Please try again.");
      setLeads(
        leads.map((lead) =>
          lead._id === lead_id
            ? { ...lead, lead_status: lead.lead_status }
            : lead
        )
      );
    } finally {
      setUpdatingLeadId(null);
    }
  };

  const handleSortChange = (event) => {
    // console.log(event.target.value, "event");

    setCurrentPage(1);
    setSortBy(event.target.value);
  };

  const handleSortInputChange = (value) => {
    // console.log(value, "value");

    if (value === "") {
      // timeDesc;
      setSortBy("timeDesc");
      setCurrentPage(1);
    } else {
      setSortBy(value);
      setCurrentPage(1);
    }
  };

  const toggleLeadExpansion = (leadId) => {
    setExpandedLeadId(expandedLeadId === leadId ? null : leadId);
  };

  // const sortedLeads = [...leads].sort((a, b) => {
  //   const aIsHotLead = a.lead_status === "fire";
  //   const bIsHotLead = b.lead_status === "fire";

  //   if (sortBy === "hotLeads") {
  //     if (aIsHotLead && !bIsHotLead) return -1;
  //     if (!aIsHotLead && bIsHotLead) return 1;
  //     return (
  //       new Date(a.createdAt || a.lastMessageDate) -
  //       new Date(b.createdAt || b.lastMessageDate)
  //     );
  //   }

  //   switch (sortBy) {
  //     case "timeAsc":
  //       return (
  //         new Date(a.createdAt || a.lastMessageDate) -
  //         new Date(b.createdAt || b.lastMessageDate)
  //       );
  //     case "timeDesc":
  //       return (
  //         new Date(b.createdAt || b.lastMessageDate) -
  //         new Date(a.createdAt || a.lastMessageDate)
  //       );
  //   }

  //   if (sortBy.length > 0) {
  //     // Buscar coincidencias en todos los campos relevantes
  //     const getAllMatches = (lead) => {
  //       const fields = [lead.name, lead.fullName, lead.phone]; // Agrega más campos si es necesario
  //       return fields
  //         .filter((field) => field !== undefined) // Ignorar campos indefinidos
  //         .some((field) => field.toLowerCase().includes(sortBy.toLowerCase())); // Verificar coincidencias
  //     };

  //     const aMatches = getAllMatches(a);
  //     const bMatches = getAllMatches(b);

  //     if (aMatches && !bMatches) return -1;
  //     if (!aMatches && bMatches) return 1;
  //   }

  //   // Ordenar por fecha por defecto si no hay coincidencias específicas
  //   return (
  //     new Date(a.createdAt || a.lastMessageDate) -
  //     new Date(b.createdAt || b.lastMessageDate)
  //   );
  // });

  const sortedLeads = [...leads].sort((a, b) => {
    const aIsHotLead = a.lead_status === "fire";
    const bIsHotLead = b.lead_status === "fire";

    // Función para convertir las fechas de manera consistente
    const parseDate = (lead) => {
      if (lead.locationId === undefined) {
        return new Date(lead.createdAt).getTime(); // Convertir string ISO a timestamp
      } else if (lead.locationId !== undefined) {
        return lead.lastMessageDate; // Usar directamente el timestamp
      }
      return 0; // En caso de no tener ninguna fecha
    };

    if (sortBy === "hotLeads") {
      if (aIsHotLead && !bIsHotLead) return -1;
      if (!aIsHotLead && bIsHotLead) return 1;

      // Ordenar por fecha si ambos son o no hot leads
      return parseDate(a) - parseDate(b);
    }

    switch (sortBy) {
      case "timeAsc":
        return parseDate(a) - parseDate(b); // Orden ascendente por fecha
      case "timeDesc":
        return parseDate(b) - parseDate(a); // Orden descendente por fecha
    }

    if (sortBy.length > 0) {
      // Buscar coincidencias en los campos relevantes
      const getAllMatches = (lead) => {
        const fields = [lead.fullName, lead.contactName, lead.phone]; // Agregar más campos según sea necesario
        return fields
          .filter((field) => field !== undefined) // Ignorar campos indefinidos
          .some((field) => field.toLowerCase().includes(sortBy.toLowerCase())); // Verificar coincidencias
      };

      const aMatches = getAllMatches(a);
      const bMatches = getAllMatches(b);

      if (aMatches && !bMatches) return -1;
      if (!aMatches && bMatches) return 1;
    }

    // Ordenar por fecha por defecto
    return parseDate(a) - parseDate(b);
  });

  const indexOfLastLead = currentPage * leadsPerPage;
  const indexOfFirstLead = indexOfLastLead - leadsPerPage;
  const currentLeads = sortedLeads.slice(indexOfFirstLead, indexOfLastLead);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const totalPages = Math.ceil(leads.length / leadsPerPage);
  const maxButtons = 3; // Número máximo de botones

  // Calcular el rango de botones a mostrar
  let startPage = Math.max(currentPage - Math.floor(maxButtons / 2), 1);
  let endPage = startPage + maxButtons - 1;

  if (endPage > totalPages) {
    endPage = totalPages;
    startPage = Math.max(endPage - maxButtons + 1, 1);
  }

  const updateLeadScriptState = (leadId, newState) => {
    setLeads(
      leads.map((lead) =>
        lead._id === leadId ? { ...lead, script_is_on: newState } : lead
      )
    );
  };

  const showMessages = (lead) => {
    if (lead.locationId !== undefined) {
      return "";
    }
    // return lead.messages.map((message, index) => {

    const originalMessage = lead.messages[0].message;

    let cleanMessage = "";

    if (
      originalMessage.includes(
        "Hi there, I'd like to have a consultation. Here are my answers to Yelp's questions regarding my request:"
      )
    ) {
      cleanMessage = originalMessage.replace(
        "Hi there, I'd like to have a consultation. Here are my answers to Yelp's questions regarding my request:",
        ""
      );
    } else if (
      originalMessage.includes(
        "Hi there! Could you help me with my project? Here are my answers to Yelp's questions regarding my project:"
      )
    ) {
      cleanMessage = originalMessage.replace(
        "Hi there! Could you help me with my project? Here are my answers to Yelp's questions regarding my project:",
        ""
      );
    }

    const question1 = "Service Needed";
    const answer1 = lead.service_type;

    // const question2 = "What type of animal needs care?";
    // const answer2 = lead.pet_type;

    const question2 = "";
    const answer2 = "";

    const question3 = "Does the pet or animal need in-home service?";
    let answer3 = "";

    if (cleanMessage.includes("Yes")) {
      answer3 = "Yes";
    } else if (cleanMessage.includes("No")) {
      answer3 = "No";
    }

    const question4 = "What type of dental care does your pet or animal need?";
    let answer4 = getDetailsResponse(
      cleanMessage,
      "What type of dental care does your pet or animal need?",
      "In what location do you need the service?"
    );

    const question5 = "Any details you would like to add?";
    let answer5 = getDetailsResponse(
      cleanMessage,
      "Any details you'd like to add?",
      "In what location do you need the service?"
    );

    const question6 = "When do you require this service?";
    let answer6 = getDetailsResponse(
      cleanMessage,
      "When do you require this service?",
      "In what location do you need the service?"
    );

    const locationQuestion = "In what location do you need the service?";
    const location = getLastWord(cleanMessage);

    let messages = "";
    // if (index === 0) {
    messages = (
      <>
        <p className="font-semibold">Lead questions:</p>
        {/* {cleanMessage} */}
        <div className="mt-4">
          <p>{question1}</p>
          <b>{answer1}</b>
        </div>

        <div className="mt-4">
          <p>{question2}</p>
          <b>{answer2}</b>
        </div>

        {answer3 !== "" && (
          <div className="mt-4">
            <p>{question3}</p>
            <b>{answer3}</b>
          </div>
        )}

        {answer4 !== "" && (
          <div className="mt-4">
            <p>{question4}</p>
            <b>{answer4}</b>
          </div>
        )}

        {answer5 !== "" && (
          <div className="mt-4">
            <p>{question5}</p>
            <b>{answer5}</b>
          </div>
        )}

        {answer6 !== "" && (
          <div className="mt-4">
            <p>{question6}</p>
            <b>{answer6}</b>
          </div>
        )}

        {location !== "" && (
          <div className="mt-4">
            <p>{locationQuestion}</p>
            <b>{location}</b>
          </div>
        )}

        <p className="font-semibold mt-4">Message 1:</p>
        <p>{lead.messages[1][0].message}</p>

        {lead.messages[2] !== undefined &&
          lead.messages[2][0] !== undefined && (
            <>
              <p className="font-semibold">Message 2:</p>
              <p>{lead.messages[2][0].message}</p>
            </>
          )}
      </>
    );
    // }

    return messages;
    // });
  };

  function getLastWord(text) {
    const trimmedText = text.trim(); // Elimina espacios en blanco al inicio y al final
    const lastSpaceIndex = trimmedText.lastIndexOf("?"); // Encuentra el último espacio

    if (lastSpaceIndex === -1) {
      return ""; // Si no hay espacios, devuelve todo el texto
    }

    return trimmedText.substring(lastSpaceIndex + 1); // Devuelve la cadena después del último espacio
  }

  function getDetailsResponse(message, questionParameter, postQuestion) {
    const question = questionParameter;
    const endMarker = postQuestion;

    // Buscar la posición donde empieza la pregunta
    const questionIndex = message.indexOf(question);

    if (questionIndex === -1) {
      return "";
    }

    // Buscar la posición donde empieza la pregunta de la ubicación
    const endMarkerIndex = message.indexOf(endMarker);

    if (endMarkerIndex === -1) {
      return "";
    }

    // Extraer la respuesta entre la pregunta y el marcador final
    let answer = message
      .substring(questionIndex + question.length, endMarkerIndex)
      .trim();

    if (answer.includes("When do you require this service?")) {
      const phraseIndex = answer.indexOf("When do you require this service?");
      answer = answer.substring(0, phraseIndex).trim();
    }

    return answer || "";
  }

  const countBizMessages = (lead) => {
    if (lead.locationId !== undefined) {
      return "?";
    }
    let count = 0;

    const messages = lead.messages;

    for (let i = 0; i < messages.length; i++) {
      if (
        i > 0 &&
        messages[i][0] !== undefined &&
        messages[i][0].userType === "BIZ"
      ) {
        count += 1;
      }
    }
    return count.toString();
  };

  const [businessAvalaible, setBusinessAvalaible] = useState("");

  const getBusiness = async () => {
    try {
      const response = await axios.get(
        `${globalVariables.domain}/business`,
        {}
      );

      const allBusiness = response.data.content;

      const businessPermited = allBusiness.filter((business) =>
        yelpIds.includes(business.business_yelp_id)
      );

      // Actualizar el estado con los negocios permitidos
      setBusinessAvalaible(businessPermited);
    } catch (error) {
      console.error(error);
    }
  };

  const getLeadsSystematik = async (business_yelp_id) => {
    try {
      const response = await axios.get(
        `${globalVariables.domain}/lead_systematik/leads/`,
        {}
      );

      const systematik_leads = response.data.content;

      let tagPriority = [];
      if (business_yelp_id === "pJQWHLMNUfJCz786v5egcg") {
        // VMCUC
        tagPriority = ["(510) 405-3673", "(510) 368-4544"];
      } else if (business_yelp_id === "X7Bfu5XULEm6DPF2aU5hsw") {
        // console.log("holaaa");
        // AAA
        tagPriority = [
          "(408) 713-5181",
          "(408) 882-1249",
          "(831) 480-1788",
          "aaa",
          "aaa web form",
        ];
      } else if (business_yelp_id === "yqN1wSXUiFP27Zv4Kx8Bng") {
        tagPriority = ["(669) 348-3151", "(669) 303-6604"];
      }

      const filteredLeads = systematik_leads.filter(
        (lead) =>
          Array.isArray(lead.tags) &&
          lead.tags.some((tag) => tagPriority.includes(tag))
      );
      // console.log(filteredLeads);

      return filteredLeads;
    } catch (error) {
      console.error(error);
      return []; // Retorna un array vacío en caso de error
    }
  };

  useEffect(() => {
    getBusiness();
  }, []);

  useEffect(() => {
    // console.log(businessSelected, "sadasdas-");
    if (businessSelected !== "") {
      setLeads([]);
      fetchLeads(businessSelected);
    }
  }, [businessSelected]);

  const chooseImgSource = (lead) => {
    const w = "w-10";
    if (lead.locationId === undefined) {
      return <img src={Yelp} alt="Yelp" className={w} />;
    } else {
      let tagPriority = [];

      if (businessSelected === "pJQWHLMNUfJCz786v5egcg") {
        // VMCUC
        tagPriority = ["(510) 368-4544", "(510) 405-3673"]; // yelp call, google
      } else if (businessSelected === "X7Bfu5XULEm6DPF2aU5hsw") {
        // AAA
        tagPriority = [
          "(408) 713-5181",
          "(408) 882-1249",
          "(831) 480-1788",
          "aaa",
          "aaa web form",
        ]; // yelp call, google, google santa cruz
      } else if (businessSelected === "yqN1wSXUiFP27Zv4Kx8Bng") {
        tagPriority = ["(669) 303-6604", "(669) 348-3151"];
      }

      if (Array.isArray(lead.tags)) {
        // Encuentra el primer tag que coincida en el array del lead
        const matchedTag = lead.tags.find((tag) => tagPriority.includes(tag));

        // Devuelve la imagen correspondiente al primer número encontrado
        if (matchedTag === tagPriority[0]) {
          return <img src={yelp_call} alt="Google" className={w} />;
        } else if (matchedTag === tagPriority[1]) {
          return <img src={google} alt="Yelp call" className={w} />;
        }

        if (matchedTag === "(831) 480-1788") {
          return <img src={google} alt="google" className={w} />;
        }

        if (matchedTag === "aaa") {
          return (
            <img src={aaa_call_center} alt="aaa_call_center" className={w} />
          );
        }

        if (matchedTag === "aaa web form") {
          return (
            <img src={aaa_form_website} alt="aaa_form_website" className={w} />
          );
        }
      }
    }
  };

  const [messagesShow, setMessagesShow] = useState([]);
  const [messageSelected, setMessageSelected] = useState("");

  const getRecording = async (id, locationId) => {
    try {
      const response = await axios.get(
        `${globalVariables.domain}/lead_systematik/recording`,
        {
          headers: {
            message_id: id,
            location_id: locationId,
          },
          responseType: "arraybuffer",
        }
      );

      // console.log(response.data, "response.data");

      return true;
    } catch (error) {
      console.error("Error al obtener la grabación:", error);
      return false;
    }
  };

  const messages = async (lead) => {
    if (lead.conversationId === "N/A") {
      setMessagesShow([]);
      return;
    }
    try {
      const response = await axios.get(
        `${globalVariables.domain}/lead_systematik/messages/${lead.conversationId}`,
        {}
      );

      // console.log(response.data.content.messages, "mesagesssss");
      // getRecording(
      //   response.data.content.messages[0].id,
      //   response.data.content.messages[0].locationId
      // );

      setMessagesShow(response.data.content.messages);

      return;
    } catch (error) {
      console.error(error);
      return;
    }
  };

  useEffect(() => {
    // console.log("cambio");
    if (expandedLeadId === null) {
      return;
    }
    if (!expandedLeadId || !currentLeads) return; // Asegúrate de que los datos existan

    // Busca el lead correspondiente en currentLeads
    const lead = Array.isArray(currentLeads)
      ? currentLeads.find((lead) => lead._id === expandedLeadId) // Si es un arreglo
      : Object.values(currentLeads).find((lead) => lead._id === expandedLeadId); // Si es un objeto

    if (lead) {
      messages(lead);
    } else {
      console.warn("Lead no encontrado");
    }
  }, [expandedLeadId]); // Escucha cambios en expandedLeadId o currentLeads

  const [isModalOpenRecording, setIsModalOpenRecording] = useState(false);

  const handleCloseModalRecording = () => {
    setIsModalOpenRecording(false);
  };

  const handleOpenModalRecording = (message) => {
    setMessageSelected(message);
    setIsModalOpenRecording(true);
    // setIsMenuOpenAllUsers(true);
  };

  return (
    <div className="container flex flex-col items-center justify-center mx-auto pb-4">
      {isModalOpenRecording && (
        <ModalListenRecording
          handleCloseModalRecording={handleCloseModalRecording}
          messageSelected={messageSelected}
          isModalOpenRecording={isModalOpenRecording}
        />
      )}
      {yelpIds.length > 1 && (
        <select
          onChange={(e) => setBusinessSelected(e.target.value)}
          className="ml-4 text-sm p-2 border-2 border-orange-500 hover:border-orange-200 rounded-md shadow-sm focus:border-orange-300 focus:ring focus:ring-orange-200 focus:ring-opacity-50"
          defaultValue="" // Valor predeterminado vacío
        >
          <option disabled value="">
            Select a Business
          </option>
          {businessAvalaible !== "" &&
            businessAvalaible.map((business) => (
              <option
                key={business.business_yelp_id}
                value={business.business_yelp_id}
              >
                {business.business_name}
              </option>
            ))}
        </select>
      )}

      {businessSelected !== "" && (
        <>
          <table className="min-w-full bg-white">
            <thead>
              <tr>
                <th className="px-2 py-3 border-b-2 border-gray-300 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                  Source
                </th>
                <th className="px-4 py-3 border-b-2 border-gray-300 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                  Client Name
                </th>
                <th className="py-3 border-b-2 border-gray-300 text-right text-xs leading-4 font-medium text-gray-500 uppercase ">
                  <input
                    onChange={(e) => {
                      handleSortInputChange(e.target.value);
                    }}
                    placeholder="Search"
                    className="mx-1 mb-2 p-2"
                  />
                  <select
                    className="mx-1 text-sm p-2 border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                    value={sortBy}
                    onChange={handleSortChange}
                  >
                    <option value="timeDesc">Recent Leads</option>
                    <option value="timeAsc">Previous leads</option>
                    <option value="hotLeads">Hot Leads</option>
                  </select>
                </th>
              </tr>
            </thead>
            <tbody>
              {currentLeads.map((lead) => (
                <React.Fragment key={lead._id}>
                  <tr
                    onClick={() => toggleLeadExpansion(lead._id)}
                    className="cursor-pointer hover:bg-gray-100"
                  >
                    <td className="flex justify-center items-center px-0 py-4  border-gray-500">
                      {chooseImgSource(lead)}
                    </td>
                    <td className="px-0 md:px-6 py-4 whitespace-no-wrap border-b border-gray-500">
                      <div className="flex items-center">
                        <div className="p-2">
                          {getStatusIcon(lead.lead_status)}
                        </div>
                        <div className="flex flex-col justify-center items-start">
                          <span className="text-gray-900 font-medium">
                            {lead.name || lead.fullName || lead.phone}
                          </span>
                          <span className="text-sm text-gray-500">
                            {lead.service_type}
                          </span>
                        </div>
                      </div>
                    </td>
                    <td className="px-0 md:px-2 py-4 whitespace-no-wrap border-b border-gray-500 text-right">
                      <div className="flex justify-end gap-1 px-2 items-center">
                        {lead.email && (
                          <svg
                            className="w-5 h-5 text-green-500"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M4 4h16a2 2 0 012 2v12a2 2 0 01-2 2H4a2 2 0 01-2-2V6a2 2 0 012-2z"
                            />
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M22 6l-10 7L2 6"
                            />
                          </svg>
                        )}
                        {lead.phone_on_yelp && (
                          <svg
                            className="w-5 h-5 text-yellow-500"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                            />
                          </svg>
                        )}
                        {lead.phone_number && (
                          <svg
                            className="w-auto h-5 text-green-500"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                            />
                          </svg>
                        )}
                        <div className="flex flex-col">
                          <span className="text-sm text-gray-500">
                            {lead.locationId === undefined
                              ? new Date(lead.createdAt).toLocaleString(
                                  "en-US",
                                  {
                                    timeZone: "America/Los_Angeles",
                                    year: "numeric",
                                    month: "2-digit",
                                    day: "2-digit",
                                  }
                                )
                              : new Date(lead.lastMessageDate).toLocaleString(
                                  "en-US",
                                  {
                                    timeZone: "America/Los_Angeles",
                                    year: "numeric",
                                    month: "2-digit",
                                    day: "2-digit",
                                  }
                                )}
                          </span>
                          {lead.locationId === undefined && (
                            <span className="text-sm text-gray-500">
                              {new Date(lead.createdAt).toLocaleString(
                                "en-US",
                                {
                                  timeZone: "America/Los_Angeles",
                                  hour12: true,
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  second: "2-digit",
                                }
                              )}
                            </span>
                          )}

                          {lead.locationId !== undefined && (
                            <span className="text-sm text-gray-500">
                              {new Date(
                                parseInt(lead.lastMessageDate, 10)
                              ).toLocaleString("en-US", {
                                timeZone: "America/Los_Angeles",
                                hour12: true,
                                hour: "2-digit",
                                minute: "2-digit",
                                second: "2-digit",
                              })}
                            </span>
                          )}
                        </div>
                        <div
                          className={`w-6 h-6 flex items-center justify-center rounded-full ${
                            lead.script_is_on
                              ? "bg-green-500"
                              : countBizMessages(lead) === "?"
                              ? "bg-yellow-500"
                              : "bg-red-500"
                          }`}
                        >
                          <span className="text-xs font-bold text-white">
                            {countBizMessages(lead)}
                          </span>
                        </div>
                      </div>
                    </td>
                  </tr>
                  {expandedLeadId === lead._id && (
                    <tr>
                      <td
                        colSpan="3"
                        className="mx-1 py-4 whitespace-no-wrap border-b border-gray-500 bg-gray-50"
                      >
                        <div className="space-y-2 px-8">
                          {lead.locationId === undefined ? (
                            <>
                              <p className="flex flex-col pb-2 px-2">
                                <strong className="font-semibold">
                                  Script:
                                </strong>{" "}
                                <LeadScriptToggle
                                  leadId={lead.lead_id}
                                  fetchLeads={fetchLeads}
                                  initialScriptState={lead.script_is_on}
                                  onUpdate={(newState) =>
                                    updateLeadScriptState(
                                      lead.lead_id,
                                      newState
                                    )
                                  }
                                  businessSelected={businessSelected}
                                />
                              </p>
                              <p className="flex flex-col px-2 pb-2">
                                <strong className="font-semibold">Name:</strong>{" "}
                                {lead.name}
                              </p>
                              <div className="flex flex-col px-2 pb-2">
                                <strong className="font-semibold">
                                  Lead status:
                                </strong>{" "}
                                <a>{lead.messages[0].message}</a>
                                <div className="flex items-center space-x-2">
                                  <span className="ml-2">
                                    {getStatusIcon(lead.lead_status)}
                                  </span>
                                  <select
                                    value={lead.lead_status || ""}
                                    onChange={(e) => {
                                      const newLeads = leads.map((l) =>
                                        l.lead_id === lead.lead_id
                                          ? {
                                              ...l,
                                              lead_status: e.target.value,
                                            }
                                          : l
                                      );
                                      setLeads(newLeads);
                                    }}
                                    className="w-full flex items-center justify-between px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                  >
                                    <option value="">Select status</option>
                                    <option value="fire">Fire</option>
                                    <option value="no interested">
                                      Not Interested
                                    </option>
                                    <option value="30 days time">
                                      30 Days Time
                                    </option>
                                    <option value="blocked us">
                                      Blocked Us
                                    </option>
                                    <option value="called/emailed">
                                      Called/Emailed
                                    </option>
                                  </select>
                                </div>
                                <div className="mt-2 flex justify-end">
                                  <button
                                    onClick={() =>
                                      updateLeadStatus(
                                        lead.lead_id,
                                        lead.lead_status
                                      )
                                    }
                                    disabled={updatingLeadId === lead._id}
                                    className={`px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 ${
                                      updatingLeadId === lead._id
                                        ? "opacity-50 cursor-not-allowed"
                                        : ""
                                    }`}
                                  >
                                    {updatingLeadId === lead._id
                                      ? "Updating..."
                                      : "Save Status"}
                                  </button>
                                </div>
                              </div>
                              <p className="flex flex-col px-2 pb-2">
                                <strong className="font-semibold">
                                  Lead submission time:
                                </strong>{" "}
                                {new Date(lead.createdAt).toLocaleString(
                                  "en-US",
                                  {
                                    timeZone: "America/Los_Angeles",
                                    year: "numeric",
                                    month: "short",
                                    day: "numeric",
                                    hour: "2-digit",
                                    minute: "2-digit",
                                    hour12: true,
                                  }
                                )}
                              </p>
                              <p className="flex flex-col px-2 pb-2">
                                <strong className="font-semibold">
                                  Lead Purpose:
                                </strong>{" "}
                                {lead.service_type}
                              </p>
                              <p className="flex flex-col px-2 pb-2">
                                <strong className="font-semibold">Type:</strong>{" "}
                                {lead.pet_type}
                              </p>
                              {lead.email && (
                                <p className="flex flex-col px-2 pb-2">
                                  <strong className="font-semibold">
                                    Email:
                                  </strong>{" "}
                                  {lead.email}
                                </p>
                              )}
                              {lead.phone_number && (
                                <p className="flex flex-col px-2 pb-2">
                                  <strong className="font-semibold">
                                    Phone:
                                  </strong>{" "}
                                  {lead.phone_number}
                                </p>
                              )}
                              {lead.phone_on_yelp && (
                                <div className="flex flex-row items-center">
                                  <svg
                                    className="w-5 h-5 text-yellow-500"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth={2}
                                      d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                                    />
                                  </svg>
                                  <a
                                    className="ml-2 text-sm text-yellow-500"
                                    href={lead.link_to_conversation_yelp}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    (phone available on yelp)
                                  </a>
                                </div>
                              )}
                              <div className="flex flex-col pb-2">
                                {showMessages(lead)}
                                <a
                                  className="flex flex-col pb-2"
                                  href={lead.link_to_conversation_yelp}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <strong className="font-semibold px-2 text-orange-500">
                                    More...
                                  </strong>
                                </a>
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="px-4">
                                {lead.fullName && (
                                  <div>
                                    <strong>Name: </strong>
                                    <span>{lead.fullName}</span>
                                  </div>
                                )}

                                <div>
                                  <strong>Phone: </strong>
                                  <span>{lead.phone}</span>
                                </div>

                                {lead.lastMessageBody &&
                                  lead.locationId !== "AAA Call Center" && (
                                    <div>
                                      <strong>Last Message: </strong>
                                      <span>{lead.lastMessageBody}</span>
                                    </div>
                                  )}

                                {lead.lastMessageType &&
                                  lead.locationId !== "AAA Call Center" && (
                                    <div>
                                      <strong>Last Message Type: </strong>
                                      <span>{lead.lastMessageType}</span>
                                    </div>
                                  )}

                                {lead.locationId === "AAA Call Center" ||
                                  (lead.locationId === "AAA Form Website" && (
                                    <>
                                      <div>
                                        <strong>Source: </strong>
                                        <span>{lead.locationId}</span>
                                      </div>

                                      {lead.email && (
                                        <div>
                                          <strong>Email: </strong>
                                          <span>{lead.email}</span>
                                        </div>
                                      )}

                                      {lead.tags[0]?.urgency && (
                                        <div>
                                          <strong>Urgency: </strong>
                                          <span>{lead.tags[0].urgency}</span>
                                        </div>
                                      )}

                                      {lead.tags[0]?.address && (
                                        <div>
                                          <strong>Address: </strong>
                                          <span>{lead.tags[0].address}</span>
                                        </div>
                                      )}

                                      {lead.tags[0]?.serviceNeeded && (
                                        <div>
                                          <strong>Service Needed: </strong>
                                          <span>
                                            {lead.tags[0].serviceNeeded}
                                          </span>
                                        </div>
                                      )}

                                      {lead.tags[0]?.service && (
                                        <div>
                                          <strong>Service Needed: </strong>
                                          <span>{lead.tags[0].service}</span>
                                        </div>
                                      )}

                                      {lead.tags[0]?.otherNeeds && (
                                        <div>
                                          <strong>Other Needed: </strong>
                                          <span>{lead.tags[0].otherNeeds}</span>
                                        </div>
                                      )}

                                      {lead.tags[0]?.otherDetails && (
                                        <div>
                                          <strong>Other Details: </strong>
                                          <span>
                                            {lead.tags[0].otherDetails}
                                          </span>
                                        </div>
                                      )}

                                      {lead.tags[0]?.preferredDateTime && (
                                        <div>
                                          <strong>Preferred Date: </strong>
                                          <span>
                                            {new Date(
                                              lead.tags[0].preferredDateTime
                                            ).toLocaleString("en-US", {
                                              dateStyle: "medium",
                                              timeStyle: "short",
                                            })}
                                          </span>
                                          {/* Ejemplo formateado: Dec 23, 2023, 1:04 PM */}
                                        </div>
                                      )}
                                    </>
                                  ))}

                                {lead.locationId !== "AAA Call Center" &&
                                  lead.locationId !== "AAA Form Website" && (
                                    <div>
                                      <div>
                                        <strong>Messages:</strong>
                                      </div>
                                      {messagesShow.map((message) => (
                                        <div
                                          key={message.id}
                                          style={{ marginBottom: "1rem" }}
                                        >
                                          <p>
                                            <strong>Date:</strong>{" "}
                                            {new Intl.DateTimeFormat("en-US", {
                                              timeZone: "America/Los_Angeles", // Zona horaria de California
                                              year: "numeric",
                                              month: "2-digit",
                                              day: "2-digit",
                                              hour: "2-digit",
                                              minute: "2-digit",
                                              second: "2-digit",
                                              hour12: true,
                                            }).format(
                                              new Date(message.dateAdded)
                                            )}
                                          </p>
                                          <p>
                                            <strong>Duration:</strong>{" "}
                                            {message.meta.call.duration
                                              ? `${message.meta.call.duration} seconds`
                                              : "N/A"}
                                          </p>
                                          <p>
                                            <strong>Status:</strong>{" "}
                                            {message.meta.call.status}
                                          </p>
                                          <p>
                                            <strong>Type:</strong>{" "}
                                            {message.messageType}
                                          </p>
                                          {message.messageType ===
                                            "TYPE_CALL" && (
                                            <button
                                              onClick={() =>
                                                handleOpenModalRecording(
                                                  message
                                                )
                                              }
                                              className="px-4 mt-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
                                            >
                                              View Transcript and Call recording
                                            </button>
                                          )}
                                        </div>
                                      ))}
                                    </div>
                                  )}
                              </div>
                            </>
                          )}
                        </div>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
            </tbody>
          </table>

          <div className="mt-4 flex justify-center">
            <nav
              className="flex items-center gap-1 rounded-md shadow-sm -space-x-px"
              aria-label="Pagination"
            >
              <button
                onClick={() => paginate(1)}
                className="flex items-center justify-center w-10 py-2 border text-sm font-medium bg-white border-gray-300 text-gray-500 hover:bg-gray-50"
              >
                {"<<"}
              </button>
              {Array.from({ length: endPage - startPage + 1 }).map(
                (_, index) => {
                  const pageIndex = startPage + index; // Calcular el índice de la página
                  return (
                    <button
                      key={pageIndex}
                      onClick={() => paginate(pageIndex)}
                      className={`flex items-center px-4 py-2 border text-sm font-medium ${
                        currentPage === pageIndex
                          ? "bg-indigo-50 border-indigo-500 text-indigo-600"
                          : "bg-white border-gray-300 text-gray-500 hover:bg-gray-50"
                      }`}
                    >
                      {pageIndex}
                    </button>
                  );
                }
              )}
              <button
                onClick={() => paginate(currentPage + 1)}
                className={`flex items-center justify-center w-10 py-2 border text-sm font-medium ${
                  currentPage === totalPages
                    ? "hidden" // Oculta el botón si estás en la última página
                    : "bg-white border-gray-300 text-gray-500 hover:bg-gray-50"
                }`}
              >
                {">"}
              </button>
            </nav>
          </div>
        </>
      )}
    </div>
  );
};

export default LeadTable;
