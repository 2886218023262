import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ClientTable from "./components/ClientTable";
import Navbar from "./components/Navbar";
import Loading from "./components/Loading";
import axios from "axios";
import ModalCreateUser from "./components/ModalCreateUser";
import globalVariables from "../GlobalVariable";
import UserUpdate from "./components/ModalUserUpdate";

const Dashboard = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [showLogo, setShowLogo] = useState(true);
  const [userData, setUserData] = useState("");
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    last_name: "",
    email: "",
    password: "",
    roles_ids: [],
    business_yelp_ids: [],
    approved: true,
  });
  const [roles, setRoles] = useState([]);
  const [successMessage, setSuccessMessage] = useState("");
  const [responseSuccess, setResponseSuccess] = useState(true);
  const [businessName, setBusinessName] = useState("");
  const [businesses, setBusinesses] = useState([]);
  const [selectedBusiness, setSelectedBusiness] = useState("");
  const [user, setUser] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem("token");
    const user_id = localStorage.getItem("user_id");

    if (token) {
      axios
        .get(`${globalVariables.domain}/user/${user_id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          if (response.data.success) {
            setUserData(response.data.content);
            setUser(response.data.content);

            const business_yelp_ids = response.data.content.business_yelp_ids;
            localStorage.setItem("business_yelp_ids", business_yelp_ids);
            setIsAuthenticated(true);

            const timer = setTimeout(() => {
              setShowLogo(false);
            }, 2000);

            return () => clearTimeout(timer);
          }
        })
        .catch((error) => {
          console.error("Error al verificar el token:", error);
          navigate("/");
        });

      axios
        .get(`${globalVariables.domain}/role`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setRoles(response.data.content);
        })
        .catch((error) => {
          console.error("Error al obtener roles:", error);
        });
    } else {
      navigate("/");
    }
  }, [navigate]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    axios
      .post(`${globalVariables.domain}/user`, formData)
      .then((response) => {
        if (response.data.success) {
          setSuccessMessage("User created successfully!");
          setResponseSuccess(true); // Éxito

          // Limpia el formulario
          setFormData({
            name: "",
            last_name: "",
            email: "",
            password: "",
            roles_ids: [],
            business_yelp_ids: [],
            approved: true,
          });

          // Establece un temporizador
          setTimeout(() => {
            setSuccessMessage("");
            setShowModal(false);
          }, 3000);
        } else {
          setSuccessMessage(response.data.content);
          setResponseSuccess(false);
          setTimeout(() => {
            setSuccessMessage("");
            setResponseSuccess(false);
          }, 3000);
        }
      })
      .catch((error) => {
        console.error("Error creating user:", error);
        setSuccessMessage("An error occurred while creating the user."); // Mensaje genérico de error
        setResponseSuccess(false); // Error
      });
  };

  useEffect(() => {
    getBusiness();
    console.log("hola mundito");
  }, []);

  const getBusiness = async () => {
    try {
      const response = await axios.get(
        `${globalVariables.domain}/business/`,
        {}
      );

      // console.log(response.data.content, "get business");

      setBusinesses(response.data.content);

      return;
    } catch (error) {
      console.error(error);
      return; // Retorna un array vacío en caso de error
    }
  };

  const handleSelectChange = (event) => {
    setSelectedBusiness(event.target.value);
  };

  return (
    <div className="min-h-screen">
      {isAuthenticated ? (
        showLogo ? (
          <Loading />
        ) : (
          <div>
            <Navbar
              userData={userData}
              setShowModal={setShowModal}
              businessName={businessName}
              formData={formData}
              setFormData={setFormData}
            />
            <ClientTable userData={userData} />
            {showModal && (
              <ModalCreateUser
                successMessage={successMessage}
                responseSuccess={responseSuccess}
                setShowModal={setShowModal}
                roles={roles}
                formData={formData}
                setFormData={setFormData}
                handleSubmit={handleSubmit}
                handleInputChange={handleInputChange}
                showModal={showModal}
                selectedBusiness={selectedBusiness}
                handleSelectChange={handleSelectChange}
                businesses={businesses}
              />
            )}
          </div>
        )
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default Dashboard;
